import styled from 'styled-components';

import { darken } from 'polished';

export const Wrapper = styled.div`
  height: 100%;
  background: linear-gradient(-90deg, #30455d, #3b5168);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 315px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  animation: fade;
  animation-duration: 1200ms;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    color: #e5e4e5;

    /* animation: fade;
    animation-duration: 800ms; */

    span {
      margin-bottom: 8px;
      color: #d6546a;
    }
  }

  @keyframes fade {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  input {
    background: rgb(0, 0, 0, 0.1);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #e5e4e5;
    margin: 0 0 10px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);

    &::placeholder {
      color: ${darken(0.3, '#e5e4e5')};
    }
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: #01a5c5;
    font-weight: bold;
    color: #e5e4e5;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.07, '#01a5c5')};
    }
  }

  a {
    color: #e5e4e5;
    margin-top: 15px;
    font-size: 16px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
`;
