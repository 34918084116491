import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Container, gridStyle } from './styles';
import Card from '~/components/Card';
import api from '~/services/api';

export default function Dashboard() {
  const classes = gridStyle();
  const [stats, setStats] = useState([
    { url: '1', dbStatus: 'checking..', serverStatus: 'waiting..' },
    { url: '2', dbStatus: 'checking..', serverStatus: 'waiting..' },
  ]);

  const token = useSelector(state => state.auth.token);
  const AuthStr = 'Bearer '.concat(token);

  useEffect(() => {
    const dashStats = async () => {
      const { data } = await api.get('company/bots', {
        headers: { Authorization: AuthStr },
      });

      setStats(data.arrBots);
    };

    dashStats();
  }, [AuthStr]);

  if (stats.length > 0) {
    return (
      <Container>
        {/* <Grid> */}
        <div className={classes.root}>
          <Grid container spacing={3} alignItems="stretch">
            {stats.map(card => (
              <Grid item lg={4} xs key={`${card.url}gi`}>
                <Card
                  key={card.url}
                  cardTitle={card.name}
                  cardValueL={`Server is ${card.serverStatus}`}
                  cardValueR={`Database is ${card.dbStatus}`}
                  colorVL={card.serverStatus === 'running' ? 'green' : 'red'}
                  colorVR={
                    card.dbStatus === 'working as expected' ? 'green' : 'red'
                  }
                  cardColor="action"
                />
              </Grid>
            ))}
          </Grid>
        </div>
        {/* </Grid> */}
      </Container>
    );
  }
  return <h1>Sua empresa não possui nenhum bot</h1>;
}
