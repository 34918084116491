import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledPaper,
  CardTitle,
  CardValueL,
  CardValueR,
  DoubleText,
} from './styles';

export default function Card({
  cardTitle,
  cardValueL,
  cardValueR,
  colorVL,
  colorVR,
}) {
  return (
    <StyledPaper elevation={8}>
      <div>
        <CardTitle>{cardTitle}</CardTitle>
        <DoubleText>
          <CardValueL isOk={colorVL}>{cardValueL}</CardValueL>
          <CardValueR isOk={colorVR}>{cardValueR}</CardValueR>
        </DoubleText>
      </div>
    </StyledPaper>
  );
}

Card.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  cardValueL: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  cardValueR: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  colorVR: PropTypes.string.isRequired,
  colorVL: PropTypes.string.isRequired,
};
