import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import RightSidebar from '~/components/RightSidebar';
import { RightSidebarContext } from '~/services/RightSidebarContext';
import { Wrapper, Content, MainStyled, ToolbarStyled } from './styles';
import Sidebar from '~/components/Sidebar';

export default function DefaultLayout({ children }) {
  const [rightSidebarStatus, setRightSidebarStatus] = useState({
    open: false,
    elementId: null,
  });

  const providerRightSidebarStatus = useMemo(
    () => ({ rightSidebarStatus, setRightSidebarStatus }),
    [rightSidebarStatus, setRightSidebarStatus]
  );

  return (
    <Wrapper>
      <Sidebar />
      <MainStyled>
        <ToolbarStyled />
        <RightSidebarContext.Provider value={providerRightSidebarStatus}>
          <Content open={rightSidebarStatus.open}>{children}</Content>
          <RightSidebar />
        </RightSidebarContext.Provider>
      </MainStyled>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
