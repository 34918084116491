import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledPaper = styled(Paper)`
  padding: 10px 20px;
  display: grid;
  align-items: flex-end;
  /* @media (max-width: 520px) {
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }
  & > div:first-of-type {
    flex-grow: 2;
  } */
`;

export const CardTitle = styled.h3`
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: darkGrey;
  margin: 0 0 10px 0;
  line-height: unset;
`;

export const CardValue = styled.p`
  margin: 0;
  font-size: 0.8rem;
  text-align: left;
`;
export const CardValueL = styled.span`
  margin: 0;
  font-size: 1rem;
  float: left;
  color: ${props => (props.isOk === 'green' ? '#8BC110' : '#D8534A')};
`;
export const CardValueR = styled.span`
  margin: 0;
  font-size: 1rem;
  float: right;
  color: ${props => (props.isOk === 'green' ? '#8BC110' : '#D8534A')};
`;

export const CardIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const DoubleText = styled.div`
  align-items: stretch;
  text-align: justify;
`;
