import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { styled as styledMaterial } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

export const Container = styled.div`
  /* display: flex; */
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 520px) {
    flex-direction: column;
  }

  max-width: auto;
  max-height: 100%;
  margin: auto;

  flex-grow: 1;
`;

export const StyledGrid = styledMaterial(Grid)({
  marginBottom: '30px',
});

export const gridStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
