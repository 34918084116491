import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

// import logo from '~/assets/logo.svg';
import logo from '~/assets/jequiti.svg';

// import { Container } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('O email inserido não é válido')
    .required('Você precisa informar um e-mail'),
  password: Yup.string().required('Você precisa informar uma senha'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  return (
    <>
      <img src={logo} alt="GoBarber" />

      <Form schema={schema} onSubmit={handleSubmit}>
        <Input name="email" type="email" placeholder="Seu email" />
        <Input
          name="password"
          type="password"
          placeholder="Sua senha secreta"
        />

        <button type="submit" className="btn-login">
          {loading ? 'Carregando...' : 'Acessar'}
        </button>
        {/* <Link to="/register">Solicitar conta</Link> */}
      </Form>
    </>
  );
}
