import styled from 'styled-components';
import { darken } from 'polished';
import { Avatar } from '@material-ui/core';
import { styled as styledMaterial } from '@material-ui/styles';

export const Wrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;
`;

export const MainStyled = styled.main`
  flex-grow: 1;
`;

export const ToolbarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 4px;
  min-height: 64px;
`;

export const Content = styled.div`
  padding: 50px;
  flex-grow: 2;
  transition: 0.2s ease-in;
  @media (max-width: 520px) {
    padding: 20px;
  }
`;

export const Nav = styled.nav`
  background-color: #30455d;
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15vw;
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    flex-grow: 2;
    padding: 0;
    li {
      padding: 0;
      p {
        margin: 0 10px 0 0;
      }
      .menu {
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: lighter;
        text-align: right;
        font-size: 0.6rem;
        padding: 25px 20px 25px 10px;
        color: #e5e4e5;
        width: 100%;
        transition: background 0.2s;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &:hover,
        &.active {
          background-color: ${darken(0.05, '#30455d')};
          border-left: 5px solid #2c5364;
          font-weight: bolder;
        }
        svg {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const Logo = styled.img`
  width: 64px;
  height: 64px;
  margin: 15px 0 20px;
  border-bottom: 1px solid #e5e4e5;
  padding-bottom: 10px;
`;

export const LogoutButton = styled.button`
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: lighter;
  text-align: right;
  background-color: transparent;
  border: none;
  font-size: 0.6rem;
  padding: 25px 20px 25px 10px;
  color: black;
  width: 100%;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &:hover {
    background-color: ${darken(0.05, '#30455d')};
    border-left: 5px solid #2c5364;
    font-weight: bolder;
  }
  svg {
    margin-left: 10px;
  }
  p {
    margin: 0;
  }
`;

export const Profile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: grey;
    margin-top: 10px;
  }
  img {
    height: 48px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  strong {
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  a:last-of-type {
    font-size: 0.6rem;
    text-transform: uppercase;
    &:hover {
      color: #04a7cd;
    }
  }
`;

export const NavHeader = styled.div`
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #e5e4e5;
  button {
    background-color: transparent;
    border: none;
  }
`;

export const AvatarStyled = styledMaterial(Avatar)({
  width: '64px !important',
  height: '64px !important',
  margin: '15px 0 20px',
  borderBottom: '1px solid #e5e4e5',
  paddingBottom: '10px',
});
